.intro {
  text-align: center;
  margin-top: 20px;
}

.intro img {
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
}

.button {
  margin: 20px 0;
}

.button a {
  color: #000000;
  font-size: 1.2rem;
  background-color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.video-section {
  margin-top: 40px;
}

.video-section h3 {
  color: #ffffff;
  font-size: 1.5rem;
}

.video-section video {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .video-section h3 {
    font-size: 1.2rem;
  }
}
