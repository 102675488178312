/* Global styles */
body, html {
    height: 100%;
    margin: 0;
    background-color: #000000; /* Background color for the entire website */
    color: white; /* Text color for better contrast on the dark background */
    font-family: Arial, sans-serif; /* Optional: Set a global font */
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Header and footer */
  header {
    background-color: #ffffff;
    color: #000000;
    padding: 1rem;
    text-align: center;
  }
  
  footer {
    background-color: #ffffff;
    -webkit-text-fill-color: #000000;
    text-align: center;
    margin-top: auto;
  }
  
  /* Main content */
  main {
    flex: 1;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-style: bold;
  }
  
  .pdf-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  a {
    color: #000000;
    text-decoration: none;
    font-weight: bold;
  }
  