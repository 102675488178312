.project-details-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  color: #ffffff;
  margin-top: 20px;
  font-weight: bold;
}

p, ul {
  margin-bottom: 20px;
  color: #ffffff;
}

.section {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

/* DAC Section: Text on the left, image on the right */
.text-left {
  width: 50%;
  padding: 20px;
}

.image-right {
  width: 50%;
  text-align: center;
}

.image-right img {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
}

/* ESP Section: Image on the left, text on the right */
.text-right {
  width: 50%;
  padding: 20px;
}

.image-left {
  width: 50%;
  text-align: center;
}

.image-left img {
  width: 55%;
  margin: 0 auto;
  border-radius: 10px;
}

/* General Styles for Smaller Screens */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .text-left, .text-right, .image-left, .image-right {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .image-right img, .image-left img {
    width: 50%;
  }
}

.project-images {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adds spacing between the images */
  margin-top: 20px;
}

.project-images img {
  width: 30%; /* Adjusts the width relative to the container */
  max-width: 250px; /* Sets a maximum width to prevent oversized images */
  border-radius: 5px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .project-images {
    flex-direction: column;
    align-items: center;
  }

  .project-images img {
    width: 80%;
    max-width: 300px; /* Slightly increase the max-width for smaller screens */
    margin-bottom: 20px; /* Adds spacing between images when stacked vertically */
  }
}
